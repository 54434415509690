@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 100;
  src:
    url('/assets/fonts/Pretendard-Thin.subset.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Thin.subset.woff') format('woff');
}
@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  src:
    url('/assets/fonts/Pretendard-Light.subset.woff2') format('woff2'),
    url('./assets/fonts/Pretendard-Light.subset.woff') format('woff');
}
@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  src:
    url('/assets/fonts/Pretendard-Regular.subset.woff2') format('woff2'),
    url('/assets/fonts/Pretendard-Regular.subset.woff') format('woff');
}
@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  src:
    url('/assets/fonts/Pretendard-Medium.subset.woff2') format('woff2'),
    url('/assets/fonts/Pretendard-Medium.subset.woff') format('woff');
}
@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  src:
    url('/assets/fonts/Pretendard-Bold.subset.woff2') format('woff2'),
    url('/assets/fonts/Pretendard-Bold.subset.woff') format('woff');
}
@font-face {
  font-family: Pretendard;
  font-style: normal;
  font-weight: 900;
  src:
    url('/assets/fonts/Pretendard-Black.subset.woff2') format('woff2'),
    url('/assets/fonts/Pretendard-Black.subset.woff') format('woff');
}

* {
  font-family: Pretendard;
  box-sizing: border-box;
}
